import { useStaticQuery, graphql } from "gatsby"

export const useContactCareersQuery = () => {
  const data = useStaticQuery(graphql`
    query ContactCareersQuery {
    wpPage(databaseId: {eq: 183}) {
        id
        contact {
            careersText
            careersImage {
              altText
              localFile {
                childImageSharp {
                gatsbyImageData(width: 1200, placeholder: TRACED_SVG)
                }
              }
            }
          }
        }
      }
  `)

  return data
}
