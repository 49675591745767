import React from "react"
import { getImage } from "gatsby-plugin-image"
import { useContactHeroQuery } from "../../../hooks/useContactHeroQuery"
import { Wrapper, StyledImg } from "./ContactHero.styles"

const ContactHero = () => {
    
  const {
    wpPage: { contact: data },
  } = useContactHeroQuery()

  const imageData = getImage(data.hero.localFile)
  const heroAltText = data.hero.altText

  return (
    <Wrapper>
      <div className="container">
        <StyledImg image={imageData} alt={heroAltText} />
      </div>
    </Wrapper>
  )
}

export default ContactHero
