import React from "react"
import { Wrapper, StyledImg } from "./Careers.styles"
import { getImage } from "gatsby-plugin-image"
import { useContactCareersQuery } from "../../../hooks/useContactCareersQuery"

const Careers = () => {

  const {
    wpPage: { contact: data },
  } = useContactCareersQuery()

  const careersText = data.careersText
  const imageData = getImage(data.careersImage.localFile)
  const imageAlt =data.careersImage.altText

  return (
    <Wrapper>
      <div className="container">
        <div className="columns">
          <div className="column">
            <StyledImg image={imageData} alt={imageAlt} />
          </div>
          <div className="column">
            <div className="text-wrapper" dangerouslySetInnerHTML={{ __html: careersText }} />
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Careers
