import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

export const StyledImg = styled(GatsbyImage)`
  width: 100%;
  height: 500px;
  @media screen and (max-width: 500px) {
        height: 250px;
        img {
            object-position: top;
        }
    }
`

export const Wrapper = styled.div`
  position: relative;
  padding: 0 0 4rem;
  @media screen and (max-width: 991px) {
        padding: 0rem 0 0;
    }
  .container{
    .columns{
        width: calc(100% + 0.75rem);
        flex-direction: row-reverse;
        @media screen and (max-width: 991px) {
            flex-direction: column;
            flex-wrap: wrap;
            width: 100%;
        }
        .column{
            :nth-child(1) {
                max-width: 40%;
                @media screen and (max-width: 991px) {
                    max-width: 100%;
                    width: 100%;
                }
                .hs-form-iframe {
                    min-height: 600px;
                    margin: 0;
                }
                h1 {
                    margin-top: 0;
                    line-height: 1em;
                    @media screen and (max-width: 991px) {
                        font-size: 56px;
                    }
                }
                form {
                    box-sizing: border-box;
                    ::placeholder {
                        color: #000;
                    }
                    input::placeholder {
                            color: #000!important;
                    }
                    input {
                        width: 100%;
                        background: none;
                        border: none;
                        border-bottom: solid 2px #000;
                        color: #000;
                        font-size: 18px;
                        border-radius: 0;
                        outline: none;
                        padding: 1rem 0;
                        ::placeholder {
                            color: #000;
                        }
                    }
                    select {
                        width: 100%;
                        background: none;
                        border: none;
                        border-bottom: solid 2px #000;
                        color: #000;
                        font-size: 18px;
                        border-radius: 0;
                        outline: none;
                        padding: 1rem 0;
                    }
                    button {
                        margin-top: 1.5rem;
                        cursor: pointer;
                    }
                }
            }
            :nth-child(2) {
                @media screen and (max-width: 991px) {

                }
                .text-wrapper {
                    /*
                    padding-left: 15%;
                    */
                    @media screen and (max-width: 991px) {
                        padding: 0;
                    }
                    h1 {
                        line-height: 1em;
                    }
                    h2{
                        margin: 0 0 1.25rem;
                    }
                    h3 {
                        color: #FF364E;
                        margin: 0 0 0.25rem;
                    }
                    h4 {
                        margin-bottom: 0.5rem;
                        color: #FF364E;
                    }
                    p {
                        font-size: 18px;
                        color: #7A757E;
                        line-height: 1.6em;
                    }
                }
            }
        }
    }
  }
`
