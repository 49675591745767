import { useStaticQuery, graphql } from "gatsby"

export const useContactFormQuery = () => {
  const data = useStaticQuery(graphql`
  query ContactFormQuery {
    wpPage(databaseId: {eq: 183}) {
      id
      contact {
        contactText
        formText
        formHtml
      }
    }
  }
  
  `)

  return data
}
