import React from 'react';
import Layout from "../components/Layout/Layout"
import Seo from '../components/Seo/Seo';
import ContactHero from '../components/Contact/ContactHero/ContactHero';
import Careers from '../components/Contact/Careers/Careers';
import Form from '../components/Contact/Form/Form';

const ContactPage = () => (
  <Layout>
    <Seo title="Contact" />
    <ContactHero />
    <Form />
    <Careers />
  </Layout>
)


export default ContactPage;
