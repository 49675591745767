import { useContactHeroQuery } from "../../../hooks/useContactHeroQuery"

const ContactHeroMask = () => {
  
  const {
    wpPage: { contact: data },
  } = useContactHeroQuery()

  const imageMask = data.heroMask.localFile.publicURL


  return (
    imageMask
  )
}

export default ContactHeroMask
