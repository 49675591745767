import React, { useState, useEffect } from "react"
import { useContactFormQuery } from "../../../hooks/useContactFormQuery"
import { Wrapper } from "./Form.styles"

const Form = () => {
  
    
  const {
    wpPage: { contact: data },
  } = useContactFormQuery()

  const contactText = data.contactText
  const formText = data.formText
  const formHtml = data.formHtml
  /*
  useEffect(() => {
    const timer = setTimeout(() => {
      console.log('This will run after 1 second!')
      window.hbspt.forms.create({
        region: "na1",
        portalId: "23183754",
        formId: "daba2a96-7afa-4a32-b69e-4a3ca357a4e0",
        target: "#formContainer"
      });
    }, 50);
    return () => clearTimeout(timer);
  }, []);
  */

  const [loaded, setLoaded] = useState(false);

  useEffect(()=> {
    const scriptTag = document.createElement('script');
    scriptTag.src= 'https://js.hsforms.net/forms/embed/v2.js';
    scriptTag.addEventListener('load', ()=>setLoaded(true));
    document.body.prepend(scriptTag);
  }, []);
  
  useEffect(()=> {
    if (!loaded) return;
    console.log('Loaded')
    window.hbspt.forms.create({
      region: "na1",
      portalId: "23183754",
      formId: "daba2a96-7afa-4a32-b69e-4a3ca357a4e0",
      target: "#formContainer"
    });
  }, [loaded]);


  return (
    <Wrapper>
      <div className="container">
        <div className="columns">
            <div className="column">
            {/*
                <div className="text-wrapper" dangerouslySetInnerHTML={{ __html: formText }} />
                <form id="contactForm" data-sb-form-api-token="API_TOKEN">
                  <div className="form-floating mb-3">
                      <input className="form-control" id="yourName" type="text" placeholder="Your name" data-sb-validations="required" />
                  </div>
                  <div className="form-floating mb-3">
                      <input className="form-control" id="email" type="email" placeholder="Email" data-sb-validations="required,email" />
                  </div>
                  <div className="form-floating mb-3">
                      <input className="form-control" id="phone" type="text" placeholder="Phone" data-sb-validations="required" />
                  </div>
                  <div className="form-floating mb-3">
                      <select className="form-select" id="howCanWeHelpYou" aria-label="How can we help you?">
                          <option value="0">How can we help you?</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                      </select>
                  </div>
                  <div className="form-floating mb-3">
                      <input className="form-control" id="estimatedBudget" type="text" placeholder="Estimated budget" data-sb-validations="required" />
                  </div>
                  <div className="d-grid">
                      <button className="btn btn-primary btn-lg disabled" id="submitButton" type="submit">Submit</button>
                  </div>
              </form>
                  */}
                  {/*
              <div className="form-html" dangerouslySetInnerHTML={{ __html: formHtml }} />
              */}
              <div id="formContainer" />



                  
            </div>
            <div className="column">
                <div className="text-wrapper" dangerouslySetInnerHTML={{ __html: contactText }} />
            </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Form
