import { useStaticQuery, graphql } from "gatsby"

export const useContactHeroQuery = () => {
  const data = useStaticQuery(graphql`
    query ContactHeroQuery {
      wpPage(databaseId: {eq: 183}) {
        id
        contact {
          hero {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            altText
          }
          heroMask {
            altText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `)

  return data
}
